import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card} from 'react-bootstrap';
import API from '../../../utils/API';
import {CircleSlowLoader} from '../../../elements';

import Page from '../Page';
import Product from '../Product';
import PageHeading from '../Page/PageHeading';

class NotFound extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			pageUrl: '',
			pageData: [],
			pageVariants: [],
		}
		//this.handleWindowScroll = this.handleWindowScroll.bind(this);
	}
	
	componentDidMount(){
		//window.addEventListener('scroll', this.handleWindowScroll, true)
		this.state.showLoader = true;
		this.state.pageUrl = this.props.match.params[0].replace(/^\/|\/$/g, '');
		
		this.loadPageContent();
	}
	
	componentWillReceiveProps(props){
		//window.addEventListener('scroll', this.handleWindowScroll, true)
		this.state.pageUrl = props.match.params[0].replace(/^\/|\/$/g, '');
		
		this.loadPageContent();
		//this.forceUpdate();
	}
	
	handleWindowScroll(){
		var wScroll = $(window).scrollTop();
		//var heroSlider = $('#page-title').outerHeight();
		
		if (wScroll > 200) {
			
			$('#primary-menu').addClass('affix');

		} else {
			$('#primary-menu').removeClass('affix');
		}
	}
	
	loadPageContent(){
		try{
			
			this.setState({showLoader:true, pageData: []});
			API.get('site/v1/page/'+this.state.pageUrl).then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({pageData: res['data'].data, pageVariants: res['data'].variants, showLoader:false});
				}
				else
				{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	
	render(){
		
		
		return(
		<React.Fragment>
		
			{this.state.showLoader ? 
				<section>
					<div className="" >
						<Row>	
							<Col md={12} >
								<CircleSlowLoader /> 
							</Col>
						</Row>
					</div> 
				</section> : 				
				Object.keys(this.state.pageData).length<=0 ?
					<React.Fragment>
					<PageHeading title="Page Not Found" to="/" />
						<section>
							<div className="container shadow-lg mt-100 py-10 px-10 bg-white" >
								<Row>	
									<Col md={12} >
										<div className="text-center" >
										  <img src="" alt="404"   className="img-fluid rounded-circle mb-3 img-thumbnail " width="550" />
										  <h1> Error 404 page not found</h1>
										  <p>The page you were looking for doesn't exist.</p>
										  <a href="javascript:history.back()"  className="btn btn-danger rounded-pill"><i data-feather="arrow-left"></i> Go back</a>
									  </div>
									</Col>
								</Row>
							</div>
						</section>
					</React.Fragment>
				: <React.Fragment>
					<PageHeading title={this.state.pageData.pagetitle} to="/" />
						{this.state.pageData.type=='page' ? 
							<Page pageData={this.state.pageData} /> :
							<Product pageData={this.state.pageData} pageVariants={this.state.pageVariants} />
						}
				</React.Fragment>
				}
			

		</React.Fragment>
		);
	}
	
}
export default NotFound;
