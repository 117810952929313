import React, { Component, Suspense, lazy } from 'react';
import { BrowserRouter , Switch, Route, Link, HashRouter, IndexRoute, Redirect } from 'react-router-dom';
import $ from 'jquery';
import {Header, Sidebar, Footer} from '../views/elements';
import {Api} from '../utils/Variables';
import API from '../utils/API';
import Cookies from 'universal-cookie';
import routes from '../views';
import {setls, removeBothSlash, loadBg} from '../utils';

class SiteLayout extends Component{
	constructor(props){
		super(props);
		this.state = {
			siteData: [],
		}
	}
	
	componentDidMount(){
		
		$("body").on("contextmenu",function(e){
			 return false;
		});
		
		$("img").mousedown(function(e){
			 e.preventDefault()
		});

		this.loadSiteinfo();		
	}
	
	loadSiteinfo(){
		try{
			
			API.get('v1/settings/me/1').then(res=>{
				if(res['data'].status=='1')
				{
					setls('site',res['data'].data);
					this.setState({siteData: res['data'].data});
				}
			});			
		}
		catch(error){ }
	}

	getType(){
		try{
			var path = window.location.pathname;
			var paths = removeBothSlash(path).trim().split('/');
			//return paths[1];
			return (paths[1] == undefined) ? 'qnm' : paths[1];
		}
		catch(error){
			return 'qnm';
		}
	}
	
	render(){
			
		return(
			<div id="wrapper" className="wrapper clearfix"> 
			
				<Header routes={routes} />
				
					<Switch>
					{routes.map((page,key)=>(
						<Route exact path={page.path}  component={page.component} key={key} />
					))}
					</Switch>					
						
				<Footer />
					
			</div>
		);
	}
	
}
export default SiteLayout;