import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card} from 'react-bootstrap';
import {Api} from '../../../utils/Variables';
import {upperCase, groupByMultiple, getDescSortOrder} from '../../../utils';
import API from '../../../utils/API';
import {CircleSlowLoader} from '../../../elements';

import Sidebar from './Sidebar';
import PageHeading from './PageHeading';

class Page extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
		}
	}
	
	componentDidMount(){
		
	}
	
	getField(fieldName){
		try{
			
			var d = this.props.pageData;
			return d[fieldName];
		}
		catch(error){
			console.log(error);
			return null;
		}
	}
	
	render(){
		
		
		/*return(
			  <div className="shadow-lg mt-100 py-10 px-20 bg-white"  >
				
				{this.getField('pagetemplate')=='withsidebar' ? 
				<Row>
					<Col md={9} >
						<div   >
							
							<h4 className="">{this.getField('pagetitle')}</h4>
							<hr />
							
							{/*<div className="share-btn">
								<ul>
									<li><a href="#"><i className="fa fa-facebook fb1"></i> Share On Facebook</a>
									</li>
									<li><a href="#"><i className="fa fa-twitter tw1"></i> Share On Twitter</a>
									</li>
									<li><a href="#"><i className="fa fa-google-plus gp1"></i> Share On Google Plus</a>
									</li>
								</ul>
							</div>*/
							/*<Row>
								<Col md={12} >
									<div dangerouslySetInnerHTML={{__html: this.getField('pagecontent')}} className="pb-20"  />
								</Col>
							</Row>
								
						</div>
						
					
					</Col>
					<Col md={3} >
						<div>
							<Sidebar />
						</div>
						
					</Col>
				</Row>
				:
				<Row>
					<Col md={12} >
					
						<div style={{minHeight: '450px'}} >
							<h3 className="mt-0">{this.getField('pagetitle')}</h3>
							{/*<div className="share-btn">
								<ul>
									<li><a href="#"><i className="fa fa-facebook fb1"></i> Share On Facebook</a>
									</li>
									<li><a href="#"><i className="fa fa-twitter tw1"></i> Share On Twitter</a>
									</li>
									<li><a href="#"><i className="fa fa-google-plus gp1"></i> Share On Google Plus</a>
									</li>
								</ul>
							</div>*/
							/*<div dangerouslySetInnerHTML={{__html: this.getField('pagecontent')}} />
						</div>
					</Col>
				</Row>
				}
				
			</div>
		);*/
		
		return(
			<section>
				<div className="container" >
					<div className="row" >
						<div className="col-md-12" >
							
							<div dangerouslySetInnerHTML={{__html: this.getField('pagecontent')}} />							
							
						</div>
					</div>
				</div>				
			</section>
		);
	}
	
}
export default Page;
