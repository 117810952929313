import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {Carousel} from 'react-bootstrap';
import API from '../../../utils/API';

class SliderAnnouncement extends Component{
	constructor(props){
		super(props);
		this.state = {
			dataList: [],
		}
	}
	
	componentDidMount(){
		
		this.loadAnnounceMents();
	}
	
	loadAnnounceMents(){
		try{
			this.setState({showLoader: true});
			var form = new FormData();
			form.append('type','announcement');
			form.append('expired','1');
			API.post('site/v1/news/list',form).then(res=>{
				if(res['data'].status=='1'){
					this.setState({dataList: res['data'].data, showLoader: false});
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	

	marqueeStop(){
		document.getElementById('mmm_an_scroll_mq').stop();
	}
	
	marqueeStart(){
		
		document.getElementById('mmm_an_scroll_mq').start();
	}
	
	render(){
		
		
		return(			
			<div className="top-announcement shadow-lg shadow-hover-xl" >
				<h4>Announcement</h4>
				<p></p>
				<ul className="announceul-top" style={{padding:'0px 0px 0px 5px'}}>
					<marquee direction="up" id="mmm_an_scroll_mq" onMouseOver={this.marqueeStop} onMouseLeave={this.marqueeStart} scrolldelay="150" style={{ marginTop: '-28px'}} className="announce">
						{this.state.dataList.map((item,i)=>{
							return <li key={i} >
								{item.title}
								<br />
								<div dangerouslySetInnerHTML={{__html: item.content}} />
							</li>;
						})
						}
					</marquee>
					</ul>
					<p>
					</p>
			</div>
					
		);
	}
	
}
export default SliderAnnouncement;