import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col, Carousel} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import API from '../../../utils/API';
import {Api} from '../../../utils/Variables';
import {getCookieField} from '../../../utils';
import {Slider} from '../../elements';

import About from './About';
import SpecialOne from './SpecialOne';
import SpecialTwo from './SpecialTwo';
import PopularItem from './PopularItem';
import HomeProducts from './HomeProducts';
import SLIDER_1 from '../../../assets/images/banners/1.jpg';


class Home extends Component{
	constructor(props){
		super(props);
		this.state = {
			
		}
	}
	
	
	
	render(){
		
		
		return(
			<React.Fragment>
				
				<section id="slider" className="slider slide-overlay-black">
					
					<Slider />
					
				</section>
				
				<About />
				
				<SpecialOne />
				
				<SpecialTwo />
				
				<HomeProducts />
				
				<PopularItem />
					
			</React.Fragment>
		);
	}
	
}
export default Home;
