import React, { Component } from 'react';
import './index.css';

class Ribbon extends Component{
	constructor(props){
		super(props);
		this.state = {
			
		}			
	}
	
	
	render(){
		return(
		   <h2 className="ribbon">
      <a className="ribbon-content">By Gerard Manley Hopkins</a>
    </h2>
		);
	}
	
}
export default Ribbon;