import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card, Modal } from 'react-bootstrap';
import {Api} from '../../../utils/Variables';
import {upperCase, groupByMultiple, getDescSortOrder, integerIndMobile, lettersOnly} from '../../../utils';
import API from '../../../utils/API';
import {CircleSlowLoader} from '../../../elements';
import ModalImage from "react-modal-image";

import DTO_IMAGE from '../../../assets/images/dot.png';
import TEST_REPORT_IMAGE from '../../../assets/images/testreport.png';
import PageHeading from '../Page/PageHeading';

class TestReport extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			showModal: false,
			btnText: 'Submit',
			dataList: [],
		}
		
		this.showTestReport = this.showTestReport.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}
	
	componentDidMount(){
		
		var info = {
		  timeOpened: new Date(),
		  timezone: new Date().getTimezoneOffset() / 60,
		  pageon: window.location.pathname,
		  referrer: document.referrer,
		  previousSites: window.history.length,
		  browserName: window.navigator.appName,
		  browserEngine: window.navigator.product,
		  browserVersion1a: window.navigator.appVersion,
		  browserVersion1b: navigator.userAgent,
		  browserLanguage: navigator.language,
		  browserOnline: navigator.onLine,
		  browserPlatform: navigator.platform,
		  javaEnabled: navigator.javaEnabled(),
		  dataCookiesEnabled: navigator.cookieEnabled,
		  dataCookies1: document.cookie,
		  dataCookies2: decodeURIComponent(document.cookie.split(';')),
		  dataStorage: localStorage,
		  sizeScreenW: window.screen.width,
		  sizeScreenH: window.screen.height,
		  sizeDocW: window.document.width,
		  sizeDocH: window.document.height,
		  sizeInW: window.innerWidth,
		  sizeInH: window.innerHeight,
		  sizeAvailW: window.screen.availWidth,
		  sizeAvailH: window.screen.availHeight,
		  scrColorDepth: window.screen.colorDepth,
		  scrPixelDepth: window.screen.pixelDepth,
		};
		//console.log(info);
		
		this.loadDataList();
	}
	
	loadDataList(){
		try{
			this.setState({showLoader: true});
			API.get('site/v1/product/testreports').then(res=>{
				if(res['data'].status=='1'){
					this.setState({showLoader: false, dataList: res['data'].data});
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	getField(fieldName){
		try{
			
			var d = this.props.pageData;
			return d[fieldName];
		}
		catch(error){
			console.log(error);
			return null;
		}
	}
	
	getVariant(fieldName){
		try{
			
			var d = this.props.pageVariants;
			var s = d.filter(function(obj){
				return(obj.variantname==fieldName);
			});
			if(Object.keys(s).length>0){
				return s[0]['variantvalue'];
			}
			else return null;
		}
		catch(error){
			return null;
		}
	}
	
	showTestReport(e){
		e.preventDefault();
		try{
			
			$.alert({
				boxWidth: '30%',
				useBootstrap: false,
				title: e.target.title,
				content: '<img src="'+e.target.href+'" />'
			});
		}
		catch(error){ }
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			this.setState({btnText: 'Submitting...'});
			API.post('site/v1/product/enquiry', $("#frmProductEnquiry").serialize()).then(res=>{
				if(res['data'].status=='1'){
					document.getElementById("frmProductEnquiry").reset();
					$.alert({
						title:'Success',
						content: res['data'].message
					});
					this.setState({showLoader: false, btnText: 'Submit'});
				}
				else{
					/* $.alert({
						title:'Error',
						content: res['data'].message
					}); */
					alert(res['data'].message);
					this.setState({showLoader: false, btnText: 'Submit'});
				}
			});
		}
		catch(e){
			this.setState({showLoader: false, btnText: 'Submit'});
			console.log(e);
		}
	}
	
	render(){
		
		return(
		<React.Fragment>
			<PageHeading title="Test Report" />
			<section id="contact1" className="contact contact-1">
				<div className="container" >
				
					<div className="row" >
					{this.state.dataList.map((item,i)=>{
							return <div className="col-xs-12 col-sm-6 col-md-3">
									<div className="dish-panel">
										<div className="dish--img">
											<a className="img-gallery-item" href="javascript:;" title={item.pagetitle} >
												{/*<img src={Api.ImageUrl + item.variantvalue} alt={item.pagetitle} style={{maxHeight: '340px'}} />*/}
												<ModalImage
												  small={Api.ImageUrl + item.variantvalue}
												  large={Api.ImageUrl + item.variantvalue}
												  alt={item.pagetitle}
												  className="cl__test__report" 
												/>
											</a>
										</div>
										<div className="dish--content">
											<h3 className="dish--title">{item.pagetitle}</h3>                    
										</div>
									   
									</div>
								</div>;
					})
					}					
					</div>
				 
				</div>
			</section>
		</React.Fragment>
		);
	}
	
}
export default TestReport;
