import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {Carousel} from 'react-bootstrap';

class SliderItem extends Component{
	constructor(props){
		super(props);
		this.state = {
			
		}
	}
	
	
	
	render(){
		
		
		return(
				<div>
				
				<div className="item slider1 active">
                    <img src="https://sarahtuckercollege.edu.in/web/wp-content/uploads/2017/12/bg-5.jpg" alt="" /> 
                    <div className="carousel-caption slider-con">
                        <h2>Welcome to <span>University</span></h2>
                        <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form</p>
                        <a href="#" className="bann-btn-1">All Courses</a><a href="#" className="bann-btn-2">Read More</a>
                    </div>
                </div>
				
				
                </div>
		);
	}
	
}
export default SliderItem;