import React, { Component } from 'react';
import './index.css';

class FbLoader extends Component{
	constructor(props){
		super(props);
		this.state = {
			
		}			
	}
	
	
	render(){
		return(
			<div className="lds-facebook"><div></div><div></div><div></div></div>
		);
	}
	
}
export default FbLoader;