import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Carousel} from 'react-bootstrap';
import API from '../../../utils/API';
import {Api} from '../../../utils/Variables';
import moment from 'moment';

import SliderItem from './SliderItem';

class Events extends Component{
	constructor(props){
		super(props);
		this.state = {
			dataList: [],
		}
	}
	
	componentDidMount(){
		//this.loadSliders();
	}
	
	getDate(value){
		try{
			return moment(value).format('DD');
		}
		catch(error){ }
	}
	
	getMY(value){
		try{
			return moment(value).format('MMM,YYYY');
		}
		catch(error){ }
	}
	
	render(){
		
		
		return(			
			<div className="bot-gal h-blog ho-event">
				<h4>Upcoming Events</h4>
				<div className="ho-event">
					<ul>
					{this.props.data.map((item,i)=>{
						return <li key={i} >
						<div className="ho-ev-date"><span>{this.getDate(item.eventstartdate)}</span><span>{this.getMY(item.eventstartdate)}</span>
							</div>
							<div className="ho-ev-link">
								<Link to={"/event/"+item.slug} >
									<h4>{item.title}</h4>
								</Link>
								<p>Venue: {item.eventvenue}</p>
							</div>
						</li>;
					})
					}
					</ul>
				</div>
			</div>
		);
	}
	
}
export default Events;