import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card} from 'react-bootstrap';
import {Api, DT_Academics, DT_Syllabus} from '../../../utils/Variables';
import {upperCase, groupByMultiple, getDescSortOrder} from '../../../utils';
import API from '../../../utils/API';
import {CircleSlowLoader} from '../../../elements';


class Empty extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			menuList: [],
		}
	}
	
	componentDidMount(){
		
		this.loadMenus();
	}
	
	loadMenus(){
		try{
			
			this.setState({showLoader:true});
			API.get('v1/menus/1').then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({showLoader: false, menuList: res['data'].data});
				}
				else
				{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	renderSubMenu(children){
		var rv = [];
		if (children && children.length > 0) {
		  rv.push(
			<ul className="menu__submenu">
			  {this.renderMenuItems(children)}
			</ul>
		  );
		  return rv;
		}
	  }
	  
	  renderMenuItems(items){
		  console.log(items);
		  var rv = [];
		items.map((item, i) => {
		  const { menulink, title, children } = item;
		  
		   rv.push(
			<li className="menu__item" key={(`item-${i}-`)}>
			  <Link href={menulink} key={(`link-${i}-`)}>
				<a className="menu__link" target={menulink}>
				  {title}
				</a>
			  </Link>
			  {this.renderSubMenu(children)}
			</li>
		  );
		});
		return rv;
	}
	  
	  
	render(){
		
		
		return(
			  <section>
				<div className="container">
				
					<Row>
						<Col md={12} >
						
							  <ul className='ace-responsive-menu sp__menu' >
								{this.renderMenuItems(this.state.menuList)}
							  </ul>
						
						</Col>
					</Row>
				
				</div>				
			</section>
		);
	}
	
}
export default Empty;
