import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import API from '../../../utils/API';
import {Api} from '../../../utils/Variables';
import {getCookieField} from '../../../utils';


class PopularItemSingle extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			dataList: [],
		}
	}

	getFieldValue(fieldName){
		try{
			var d = this.props.product;
			return d[fieldName];
		}
		catch(error){ }
	}
	
	render(){
		
		
		return(
			<div className="col-xs-12 col-sm-6 col-md-3 productFilter filter-desserts filter-soup">
                <div className="product-item">
                    <div className="product--img">
                        <img src={Api.ImageUrl + this.getFieldValue('featureimage')} alt={this.getFieldValue('pagetitle')} style={{height: '200px'}} />
                         <div className="product--hover">
                            <div className="product--action">
                                <Link to={"/"+this.getFieldValue('pageurl')} >Read More</Link>
                            </div>
                        </div>
                        
                    </div>
                    <div className="product--content">
                        
                        <div className="product--title" style={{minHeight: '47px'}} >
                            <h3 ><Link to={"/"+this.getFieldValue('pageurl')} >{this.getFieldValue('pagetitle')}</Link></h3>
                        </div>
						{/*<div className="product--price">
                                <span>Rice flour , gram flolur, Orinda flour...</span>
						</div>*/}
                    </div>
                </div>
            </div>
		);
	}
	
}
export default PopularItemSingle;
