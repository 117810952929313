
const COOKIE_KEY = 'siteccfsa';

const Api = {
	Url :'https://webadmin.ccfsavouries.com/service/api/',
	ImageUrl: 'https://webadmin.ccfsavouries.com/service/',
	AcademicPath: ''
	
	/*Url :'http://localhost/subbu/siteservice/api/',
	ImageUrl: 'http://localhost/subbu/siteservice/',
	AcademicPath: ''*/
};

export {
	COOKIE_KEY,
	Api,
};
