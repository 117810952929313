import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {Carousel} from 'react-bootstrap';
import API from '../../../utils/API';
import {Api} from '../../../utils/Variables';
import {CircleLoader} from '../../../elements';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import SliderItem from './SliderItem';

class Slider extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: true,
			dataList: [],
			activeSlide: 0
		}
	}
	
	componentDidMount(){
		window.addEventListener('scroll', this.handleWindowScroll, true)
		this.loadSliders();
	}
	
	handleWindowScroll(){
		var wScroll = $(window).scrollTop();
		var heroSlider = $('.rev_slider_wrapper').outerHeight();
		
		if (wScroll > (heroSlider - 200)) {
			
			$('#primary-menu').addClass('affix');

		} else {
			$('#primary-menu').removeClass('affix');
		}
	}
	
	loadSliders()
	{
		try{
			this.setState({showLoader: true});
			API.get('site/v1/sliders/').then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({dataList: res['data'].data, showLoader: false});
				}
				else
				{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	componentWillMount(){
		
		$('.myslider-title-container').parent('div').css('z-index','999');
	}
	
	
	render(){
		
		const Items = [
			{
				src: 'http://sarahtuckercollege.edu.in/web/wp-content/uploads/2017/12/bg-5.jpg',
				caption: 'Slider I',
				header: '',
				altText: '',
				key: '1'
			},
			{
				src: 'http://sarahtuckercollege.edu.in/web/wp-content/uploads/2018/04/bg-6.jpg',
				caption: 'Slider II',
				header: '',
				altText: '',
				key: '2'
			},
			{
				src: 'http://sarahtuckercollege.edu.in/web/wp-content/uploads/2017/12/bg33.jpg',
				caption: 'Slider III',
				header: '',
				altText: '',
				key: '3'
			}
		];
		
		
		if(this.state.showLoader){
			return(
				<div className="py-20" className="slider_placeholder" >
					<center> 
						<CircleLoader />
					</center> 
				</div>
			);
		}
		
		return(		
		<div className="rev_slider_wrapper">
			{/* <Carousel>
				
				{this.state.dataList.map((item,i)=>{
					return <Carousel.Item>
						<img src={Api.ImageUrl + item.url} alt=""  className="d-block w-100" /> 				
						{item.title.length > 0 ? <Carousel.Caption><h3>{item.title}</h3></Carousel.Caption> : null}
					</Carousel.Item>;
				})
				}
			</Carousel> */}
			 <AwesomeSlider>
				{this.state.dataList.map((item,i)=>{
					return <div data-src={Api.ImageUrl + item.url}  >
						<div className="myslider-title-container" ></div>
							<div className="slide--subheadline">{item.title}</div>
							<div className="slide--headline">{item.content}</div>
						
					</div>;
				})
				}
			  </AwesomeSlider>
		</div>
		);
	}
	
}
export default Slider;