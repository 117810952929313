import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {Carousel} from 'react-bootstrap';

class SliderItem extends Component{
	constructor(props){
		super(props);
		this.state = {
			
		}
	}
	
	render(){
		
		var item = this.props.item;
		return(
			<Carousel.Item>
				<img src={this.props.url + item.url} alt=""  className="d-block w-100" /> 				
				{item.title.length > 0 ? <Carousel.Caption><h3>{item.title}</h3></Carousel.Caption> : null}
			</Carousel.Item>
		);
	}
	
}
export default SliderItem;