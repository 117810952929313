import React, { Component } from 'react';
import {Redirect, Link, NavLink} from 'react-router-dom';
import $ from 'jquery';
import Cookies from 'universal-cookie';
import API from '../../../utils/API';
import {Api} from '../../../utils/Variables';
import {getCookieField, capitalizeFirst, makeUrl, getls} from '../../../utils';
import TidioLiveChat from './TidioLiveChat';

class Footer extends Component{
	constructor(props){
		super(props);
		this.state = {
			categoryList: [],
		}
	}
	
	render(){
				
		return(
			<footer id="footer" className="footer footer-1 text-center">				
				<div className="footer--widget text--center">
					<div className="container">
						<div className="row">
							<div className="col-xs-12 col-sm-3 col-md-4">
								<div className="footer--widget-content heading">
									<h3>Our address</h3>
									<span className="heading--subtitle">S.CHELLIAH CHETTIAR FIRM</span>
									<p>Office : No 2 Dhargas Mani Road,Kanndapalayam<br />West Tambaram-600045,
										Chennai, <br />Tamilnadu. India</p>								   
								</div>
							</div>
							<div className="col-xs-12 col-sm-6 col-md-4">
								<div className="footer--reservation">
									<div className="divider--shape-10up"></div>
									<h3>S.CHELLIAH CHETTIAR FIRM</h3>
									<div className="divider--shape-11"></div>
									<div className="footer--reservation-wrapper">
										<p>Factory : No :652,pallaku road,Muthu Nagar,<br />South Thittankulam, Kovilpatti-628501
											Tamilnadu. India</p>								   
									</div>
									<div className="divider--shape-10down"></div>
								</div>
							</div>
							<div className="col-xs-12 col-sm-3 col-md-4">
								<div className="footer--widget-content">
									<h3>Contact us</h3>
									<p className="mb-0">Phone: {getls('site','phone')}</p>
									<p className="mb-0">Mobile: {getls('site','mobile')}</p>
									<p className="mb-0">Email: ccfsavouries@gmail.com</p>
								</div>
								<div className="terms-p">
									<Link to="/terms-and-conditions">Terms & Condition</Link> | 
									<Link to="privacy-policy">Privacy Policy</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="footer--social">
					<div className="container">
						<div className="row">
							<div className="col-xs-12 col-sm-12 col-md-12 text-center">
								<div className="footer--social-icons">
									<a href="https://www.facebook.com/CCF-Savouries-118036719601784/"><i className="fa fa-facebook"></i></a>
									<a href="https://twitter.com/CSavouries"><i className="fa fa-twitter"></i></a>
									<a href="https://www.instagram.com/ccf_savouries/"><i className="fa fa-instagram"></i></a>
									<a href="#"><i className="fa fa-google-plus"></i></a>
									<a href="#"><i className="fa fa-youtube"></i></a>
								</div>
							</div>
						   
						</div>
					</div>
				</div>
				<div className="footer--copyright text-center">
					<div className="divider--shape-dark"></div>
					<div className="container">
						<div className="row">
							<div className="col-xs-12 col-sm-12 col-md-12">
								<span>© 2019 S. Chelliah chettiar firm Exporter of Savouries. All rights reserved. powered by</span> <a target="_blank" href="http://www.paulgroups.com/">Paulgroups</a>
							</div>
						</div>
					</div>
				</div>
				
				<TidioLiveChat />
				
			</footer>
		);
	}
	
}
export default Footer;