import React, { Component } from 'react';
import {Redirect, Link, NavLink} from 'react-router-dom';
import $ from 'jquery';
import Cookies from 'universal-cookie';
import API from '../../../utils/API';
import {Api} from '../../../utils/Variables';
import {getCookieField, capitalizeFirst, makeUrl, getAscSortOrder, removeBothSlash} from '../../../utils';

import {FbLoader} from '../../../elements';

import Menu from '../Menu';


class TopMenu extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			menuList: [],
			htmlStrTxt: [],
		}
		
		this.handleMenuHover = this.handleMenuHover.bind(this);
	}
	
	componentDidMount(){
		
		this.loadMenus();
		
	}
	
	getInnerStaticMenuList(menus){
		var rv = [];
		menus.map((item,i)=>{
			rv.push({children: [], linktype: "page", menuid: "0", menulink: item.link , menuorder: "0", parentid: "0", postid: "0" ,title: item.name});
		});
		return rv;
	}
	
	loadMenus(){
		try{
			
			this.setState({showLoader:true});
			API.get('v1/menus/1').then(res=>{
				if(res['data'].status=='1')
				{
					var d = [];//
					/* d.push({ children: [], linktype: "page", menuid: "0", menulink: "/", menuorder: "0", parentid: "0", postid: "0" ,title: "Home"});
					
				var topChild = [];
				topChild.push({children: this.getInnerStaticMenuList(DT_Programmes) , linktype: "page", menuid: "0", menulink: "/", menuorder: "0", parentid: "0", postid: "0" ,title: "Programmes"});
				
				topChild.push({children: this.getInnerStaticMenuList(DT_Syllabus), linktype: "page", menuid: "0", menulink: "/", menuorder: "0", parentid: "0", postid: "0" ,title: "Syllabus"});
				
				topChild.push({children: this.getInnerStaticMenuList(DT_Courseoutcomes), linktype: "page", menuid: "0", menulink: "/", menuorder: "0", parentid: "0", postid: "0" ,title: "Course Outcomes"});
				
				topChild.push({children: [], linktype: "page", menuid: "0", menulink: "/", menuorder: "0", parentid: "0", postid: "0" ,title: "Teaching Plan"});
				topChild.push({children: [], linktype: "page", menuid: "0", menulink: "/", menuorder: "0", parentid: "0", postid: "0" ,title: "Others"});
					
				//d.push({ children: topChild, linktype: "page", menuid: "0", menulink: "/", menuorder: "0", parentid: "0", postid: "0" ,title: "Academics"});
					
					//d.sort(getAscSortOrder('menuorder'));
					
					res['data'].data.map((item,i)=>{
						d.push(item);
					});
					 */
					 d = res['data'].data;
					this.setState({showLoader: false, menuList: d});
				}
				else
				{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	handleMenuHover(mId){
		try{	

			//document.getElementById(mId).style.display = 'block';
			var ele = document.getElementById(mId);
			$(ele).fadeIn();
			
		}
		catch(error){
			console.log(error);
		}
	}
	
	handleMenuOut(mId){
		try{
			
			var ele = document.getElementById(mId);
			$(ele).fadeOut();
			
		}
		catch(error){
			console.log(error);
		}
	}
	
	getInnerMenus(item){
		try{
			
		}
		catch(error){
			
		}
	}
	
	loadMenuTree(par, lvl){
		/* this.state.htmlStrTxt.push('<ul>');  
        for(var x in menuItems) {  
            if (menuItems[x].parentid == par) {  
                this.state.htmlStrTxt.push('<li><span class="level-' + lvl + '">' + menuItems[x].title + '</span>');  
                 
                if (menuItems[x].childCount > 0) {  
                    this.state.htmlStrTxt.push(recurseMenu(menuItems[x].id, lvl + 1));  
                }  
                this.state.htmlStrTxt.push('</li>');  
            }  
        }  
         this.state.htmlStrTxt.push('</ul>');  
		 return this.state.htmlStrTxt; */
	}
	

	
	renderSubMenu(children, mId){
		var rv = [];
		if (children && children.length > 0) {
		  rv.push(
			<ul  className="dropdown"  >
			  {this.renderMenuItems(children)}
			</ul>
		  );
		  return rv;
		}
	  }
	  
	renderMenuItems(items){
		  
		  var rv = [];
		items.map((item, i) => {
		  const { menulink, title, children , id} = item;
		  
		  const mId = 'i__sm__'+id;
		  const childList = this.renderSubMenu(children, mId);
		   rv.push(
			<li className={childList!=undefined && childList.length> 0 ? "drop-down" : ''}  >
				{childList!=undefined && childList.length> 0 ? 
				<a href="#" >
				  {title}
				</a>
				:
				<NavLink exact to={'/'+removeBothSlash(menulink)}>
				  {title}
				</NavLink>
				}
			  {childList}
			</li>
		  );
		});
		return rv;
	}
	
	componentWillUpdate(){
		
		/* if ($('.main-nav').length) {
			var $mobile_nav = $('.main-nav').clone().prop({
			  class: 'mobile-nav d-lg-none'
			});
			$('body').append($mobile_nav);
			$('body').prepend('<button type="button" class="mobile-nav-toggle d-lg-none"><i class="fa fa-bars"></i></button>');
			$('body').append('<div class="mobile-nav-overly"></div>');

			$(document).on('click', '.mobile-nav-toggle', function(e) {
			  $('body').toggleClass('mobile-nav-active');
			  $('.mobile-nav-toggle i').toggleClass('fa-times fa-bars');
			  $('.mobile-nav-overly').toggle();
			});
			
			$(document).on('click', '.mobile-nav .drop-down > a', function(e) {
			  e.preventDefault();
			  $(this).next().slideToggle(300);
			  $(this).parent().toggleClass('active');
			});

			$(document).click(function(e) {
			  var container = $(".mobile-nav, .mobile-nav-toggle");
			  if (!container.is(e.target) && container.has(e.target).length === 0) {
				if ($('body').hasClass('mobile-nav-active')) {
				  $('body').removeClass('mobile-nav-active');
				  $('.mobile-nav-toggle i').toggleClass('fa-times fa-bars');
				  $('.mobile-nav-overly').fadeOut();
				}
			  }
			});
		  } else if ($(".mobile-nav, .mobile-nav-toggle").length) {
			$(".mobile-nav, .mobile-nav-toggle").hide();
		  } */
	}
	
	loadSubMenus(items){
		var rv = [];
		try{
			
			items.map((item,i)=>{
				rv.push(<div className="col-md-3">
					<ul>
						<li>
							<NavLink to={item.menulink} >{item.title}</NavLink>	
						</li>
					</ul>
				</div>);
			});
		
			return rv;
		}
		catch(error){
			return rv;
		}
	}
	 
	loadMenuList(item){
		var rv = [];
		try{

			if(Object.keys(item).length>0 && item.children && Object.keys(item.children).length>0){
				rv.push(<li className="has-dropdown mega-dropdown ">
						<a href="javascript:;" data-toggle="dropdown" className="dropdown-toggle menu-item">{item.title}</a>
						<ul className="dropdown-menu mega-dropdown-menu">
							<li>
								<div className="container">
									<div className="row">
										{this.loadSubMenus(item.children)}
									</div>
								</div>
							</li>
						</ul>
					</li>);
			}
			else{
				
				rv.push(<li >
					<NavLink to={item.menulink} className="menu-item">{item.title}</NavLink>	
				</li>);
			}
			return rv;
		}
		catch(error){
			return rv;
		}
	}
	
	render(){
		
		
		return(
			<ul className="nav navbar-nav nav-pos-right navbar-left">
							
				{this.state.menuList.map((item,i)=>{
					return this.loadMenuList(item);
				})
				}

			</ul>
		);
	}
	
}
export default TopMenu;