import React, { Component } from 'react';
import './style.css';

class Loader extends Component{
	constructor(props){
		super(props);
		this.state = {
			loginText: 'Login',
			isRedirect: false,
			showLoader: false,
		}
		
		
	}
	
	
	render(){
		
		
		
		return(
			<div className="sus_loader" >
				<div className="lds-ripple"><div></div><div></div></div>			
			</div>
		);
	}
	
}
export default Loader;