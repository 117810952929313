import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import API from '../../../utils/API';
import {Api} from '../../../utils/Variables';
import {getCookieField} from '../../../utils';

import BANNER_1 from '../../../assets/images/banners/6.jpg';

class SpecialOne extends Component{
	constructor(props){
		super(props);
		this.state = {
			
		}
	}
	
	componentDidMount(){
	
	}
	
	
	render(){
		
		
		return(
			<section id="banner3" className="banner banner-3 text-center bg-white pt-50 pb-50">
				<div className="container">
					<div className="row">
						<div className="col-xs-12 col-sm-6 col-md-6">
							<div className="banner-img">
								<img src={BANNER_1} alt="image" style={{width: '90%'}} />
							</div>
						</div>
						

						<div className="col-xs-12 col-sm-12 col-md-6">
							<div className="heading heading-1 text--center mt-20">
								
								<h2 className="heading--title">Special Mixture</h2>
								<div className="divider--shape-4"></div>
								<p className="heading--desc">
									&emsp; We produce this mixture with high quality pulses and also it contains high nutrients of nuts ( BADAM AND CASHEW ). We induce our consumer with high and rich masala taste, smell of garlic and hing, We maintain international standard quality.
								</p>
							   
							</div>
						</div>

					</div>
				</div>
			</section>
		);
	}
	
}
export default SpecialOne;
