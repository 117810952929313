import React, { Component } from 'react';
import {Redirect, Link, NavLink} from 'react-router-dom';
import $ from 'jquery';
import Cookies from 'universal-cookie';
import API from '../../../utils/API';
import {Api} from '../../../utils/Variables';
import {getCookieField, capitalizeFirst, makeUrl, getls} from '../../../utils';

class TidioLiveChat extends Component{
	constructor(props){
		super(props);
		this.state = {
			categoryList: [],
		}
		this.onTidioChatApiReady = this.onTidioChatApiReady.bind(this);
	}
	
	componentDidMount(){
		 var tidioScript = document.createElement("script");
		  tidioScript.src = "//code.tidio.co/jvtda01gcfpvov9b53zwkdynog3nsezb.js";
		  document.body.appendChild(tidioScript);
		  
		   if (window.tidioChatApi) {
			window.tidioChatApi.on("ready", this.onTidioChatApiReady);
		  } else {
			document.addEventListener("tidioChat-ready", this.onTidioChatApiReady);
		  }
	}
	
	onTidioChatApiReady() {
		//window.tidioChatApi.open();
	}
	
	render(){
		
		return(
			<div >
			
			</div>
		);
	}
	
}
export default TidioLiveChat;