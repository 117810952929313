import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import API from '../../../utils/API';
import {Api} from '../../../utils/Variables';
import {getCookieField} from '../../../utils';

import BANNER_1 from '../../../assets/images/banners/7.jpg';

import PopularItemSingle from './PopularItemSingle';

class PopularItem extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			dataList: [],
		}
	}
	
	componentDidMount(){
		this.loadData();
	}
	
	loadData(){
		try{
			this.setState({showLoader: true});
			API.get('site/v1/product/homepage/popular/3').then(res=>{
				if(res['data'].status=='1'){
					
					this.setState({dataList: res['data'].data, showLoader: false});
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){ 
			this.setState({showLoader: false});
		}
	}
	
	render(){
		
		
		return(
			<section id="shop" className="shop shop-4 bg-white">
				<div className="container">
					<div className="row clearfix">
						<div className="col-xs-12 col-sm-12 col-md-8 col-md-offset-2">
							<div className="heading heading-3 mb-30 text--center">
								
								<h2 className="heading--title mb-0">Popular Murukku</h2>
								<div className="divider--shape-4"></div>
							</div>
						</div>
					</div>
					<div className="row">
					{this.state.dataList.map((item,i)=>{
						return <PopularItemSingle product={item} />;
					})
					}						
					</div>
				</div>
			</section>
		);
	}
	
}
export default PopularItem;
