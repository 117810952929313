import React, { Component } from 'react';
import {Redirect, Link, NavLink} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card} from 'react-bootstrap';
import {Api, DT_Academics, DT_QuickLinks} from '../../../utils/Variables';
import {upperCase, groupByMultiple, loadBg} from '../../../utils';

import PAGE_TITLE_IMG from '../../../assets/images/page-title/3.jpg';

class PageHeading extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
		}
		this.handleWindowScroll = this.handleWindowScroll.bind(this);
	}
	
	componentDidMount(){
		window.addEventListener('scroll', this.handleWindowScroll, true)
		loadBg();
	}
	
	componentWillReceiveProps(){
		window.addEventListener('scroll', this.handleWindowScroll, true)
	}
	
	handleWindowScroll(){
		var wScroll = $(window).scrollTop();
		//var heroSlider = $('#page-title').outerHeight();
		
		if (wScroll > 200) {
			
			$('#primary-menu').addClass('affix');

		} else {
			$('#primary-menu').removeClass('affix');
		}
	}
	
	
	render(){
		
		
		return(
			  <section id="page-title" className="page-title bg-overlay bg-parallax bg-overlay-gradient">
				<div className="bg-section">
					<img src={PAGE_TITLE_IMG} alt="Background" />
				</div>
				<div className="container">
					<div className="row">
						<div className="col-xs-12 col-sm-12 col-md-12">
							<div className="title title-1 text-center">
								<div className="title--content">
									<div className="title--heading">
										<h1>{this.props.title}</h1>
									</div>
								</div>
								<div className="clearfix"></div>
								<ol className="breadcrumb">
									<li><Link to="/" >Home</Link></li>                        
									<li className="active">{this.props.title}</li>
								</ol>
								<div className="divider--shape-1down"></div>
							</div>
						</div>
					</div>
				</div>
			</section>						
		);
	}
	
}
export default PageHeading;
