import {lazy} from 'react';

/*const Home = lazy(() => import('./pages/Home'));
const Contact = lazy(() => import('./pages/Contact'));
const NotFound = lazy(() => import('./pages/NotFound'));
const Programmes = lazy(() => import('./pages/Programmes'));
const Syllabus = lazy(() => import('./pages/Syllabus'));
const Course = lazy(() => import('./pages/Course'));*/

import Home from './pages/Home';
import Gallery from './pages/Gallery';
import GalleryList from './pages/Gallery/GalleryList';
import Empty from './pages/Page/Empty';
import NotFound from './pages/NotFound';
import Enquiry from './pages/Enquiry';
import TestReport from './pages/TestReport';

const pageList = [
	{ name: 'Home', path: '/', component: Home }, 
	{ name: 'Enquiry', path: '/contact-us', component: Enquiry },
	{ name: 'Enquiry', path: '/test-report', component: TestReport },
	{ name: 'Gallery', path: '/gallery', component: Gallery },
	{ name: 'Gallery', path: '/gallery/:slug', component: GalleryList },
	{ name: 'Empty', path: '/empty', component: Empty },
	{ name: 'Not Found', path: '*', component: NotFound },
];
export default pageList;