import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import API from '../../../utils/API';
import {Api} from '../../../utils/Variables';
import {getCookieField} from '../../../utils';


class PopularItemSingle extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			dataList: [],
		}
	}

	getFieldValue(fieldName){
		try{
			var d = this.props.product;
			return d[fieldName];
		}
		catch(error){ }
	}
	
	render(){
		
		
		return(
			 <div className="col-xs-12 col-sm-6 col-md-4 productFilter filter-salads">
                <div className="product-item">
                    <div className="product--img">
                        <img src={Api.ImageUrl + this.getFieldValue('featureimage')} alt={this.getFieldValue('pagetitle')} style={{height: '250px'}} />
                        <div className="divider--shape-1down"></div>
                    </div>
                    <div className="product--content">
                        <div className="product--title">
                            <h3><Link to={"/"+this.getFieldValue('pageurl')} >{this.getFieldValue('pagetitle')}</Link></h3>
                        </div>
                        {/*<div className="product--price">
                            <span>Rice flour , gram flolur...</span>
                        </div>*/}
                        <Link className="btn btn--secondary btn--bordered mt-20" to={"/"+this.getFieldValue('pageurl')} >Read More</Link>
                    </div>
                </div>
            </div>
		);
	}
	
}
export default PopularItemSingle;
