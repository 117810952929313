import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {Carousel} from 'react-bootstrap';
import API from '../../../utils/API';
import SliderAnnouncement from './SliderAnnouncement';

class TopScroll extends Component{
	constructor(props){
		super(props);
		this.state = {
			dataList: [],
		}
	}
	
	componentDidMount(){
		
		this.loadNews();
	}
	
	loadNews()
	{
		try{
			API.get('site/v1/topscroll').then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({dataList: res['data'].data});
				}
			});
		}
		catch(error){
			
		}
	}
	
	marqueeStop(){
		document.getElementById('mmm_top_scroll_mq').stop();
	}
	
	marqueeStart(){
		
		document.getElementById('mmm_top_scroll_mq').start();
	}
	
	render(){
		
		
		return(
			<div>
				<div className="row top-news-container" >
					<div className="col-md-1" >
						<span className="top-news-title">Latest News</span>
					</div>
					<div className="col-md-11" >
						<ul className="announceul" style={{padding:'0px 5px 0px 20px'}} >
							<marquee id="mmm_top_scroll_mq" scrolldelay="150" onMouseOver={this.marqueeStop} onMouseLeave={this.marqueeStart}  >
								{this.state.dataList.map((item,i)=>{
									return <li key={i} >
										{item.title}
									</li>;
								})
								}	
							</marquee>		
						</ul>
					</div>
				</div>
					
			</div>
		);
	}
	
}
export default TopScroll;