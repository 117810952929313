import React, { Component } from 'react';
import { Redirect, Link, NavLink } from 'react-router-dom';
import $ from 'jquery';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import Cookies from 'universal-cookie';
import API from '../../../utils/API';
import { Api } from '../../../utils/Variables';
import { getCookieField, capitalizeFirst, makeUrl, getls } from '../../../utils';

import LOGO_LIGHT from '../../../assets/images/logo/logo-light.png';
import LOGO_DARK from '../../../assets/images/logo/logo-dark.png';

import TopMenu from './TopMenu';
import TopScroll from '../TopScroll';

class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			categoryList: [],
		}

		this.handleMobileMenuOpen = this.handleMobileMenuOpen.bind(this);
	}

	componentDidMount() {
		/*window.addEventListener('scroll', this.handleWindowScroll, true);
		$('.has-dropdown').mouseenter(function(){
			var $this = $(this);
			$this
				.find('.dropdown')
				.css('display', 'block')
				.addClass('animated-fast fadeInUpMenu');

		}).mouseleave(function(){
			var $this = $(this);
			$this
				.find('.dropdown')
				.css('display', 'none')
				.removeClass('animated-fast fadeInUpMenu');
		})*/

	}


	handleWindowScroll() {
		//var header = document.getElementById("top-header");
		var header = document.getElementsByClassName("top-menu")[0];
		// Get the offset position of the navbar
		var sticky = header.offsetTop;

		if (window.pageYOffset > sticky) {
			header.classList.add("sticky");
		} else {
			header.classList.remove("sticky");
		}
	}


	handleMobileMenuOpen() {
		var menuContainer = $("#navbar-collapse-mobile");
		//$(menuContainer).addClass('in');
		if ($(menuContainer).hasClass('in')) {
			$(menuContainer).removeClass('in');
		}
		else {
			$(menuContainer).addClass('in');
		}
	}


	render() {


		$('html, body').animate({ scrollTop: 0 }, 'slow');

		return (
			<header id="navbar-spy" className="header header-3 header-transparent header-bordered header-fixed">
				<nav id="primary-menu" className="navbar navbar-fixed-top">
					<div className="container">
						<div className="navbar-header">
							<button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar-collapse-mobile" aria-expanded="false" onClick={this.handleMobileMenuOpen} >
								<span className="sr-only">Toggle navigation</span>
								<span className="icon-bar"></span>
								<span className="icon-bar"></span>
								<span className="icon-bar"></span>
							</button>
							<Link className="logo" to="/" >
								<img className="logo-light" src={LOGO_LIGHT} alt="CCFSE Logo" />
								<img className="logo-dark" src={LOGO_DARK} alt="CCFSE Logo" />
							</Link>
						</div>

						<div className="collapse navbar-collapse pull-right" id="navbar-collapse-2">
							<TopMenu />
						</div>

						<div className="collapse navbar-collapse pull-right" id="navbar-collapse-mobile">
							<ul className="nav navbar-nav nav-pos-right navbar-left  hidden-lg hidden-md">
								<li className="has-dropdown mega-dropdown ">
									<Link to="/index" className="menu-item">home</Link>

								</li>
								<li className="has-dropdown active">
									<Link to="/about-us" className="menu-item">About Us</Link>
								</li>

								<li className="has-dropdown">
									<Link to="/#" data-toggle="dropdown" className="dropdown-toggle menu-item">SNACKS & FOOD</Link>
									<ul className="dropdown-menu mega-dropdown-menu">
										<li>
											<div className="container">
												<div className="row">
													<div className="col-md-3">
														<ul>
															<li>
																<Link to="/mullu-murukku">Mullu Murukku</Link>
															</li>
															<li>
																<Link to="/kara-mullu-murukku">Kara Mullu Murukku:</Link>
															</li>
															<li>
																<Link to="/raagi-murukku">Raagi Murukku</Link>
															</li>
															<li>
																<Link to="/mint-murukku">Mint Murukku</Link>
															</li>
															<li>
																<Link to="/kara-thattai-murukku">Kara Thattai Murukku:</Link>
															</li>
															<li>
																<Link to="/pepper-thattai">Pepper Thattai</Link>
															</li>
															<li>
																<Link to="/raagi-thattai">Raagi Thattai</Link>
															</li>
															<li>
																<Link to="/banana-chips">Banana Chips</Link>
															</li>
														</ul>
													</div>

													<div className="col-md-3">
														<ul>

															<li>
																<Link to="/mint-thattai">Mint Thattai</Link>
															</li>
															<li>
																<Link to="/thenkulal-murukku">Thenkulal Murukku:</Link>
															</li>
															<li>
																<Link to="/spicy-thenkulal-murukku">Spicy Thenkulal Murukku:</Link>
															</li>
															<li>
																<Link to="/kerala-murukku">Kerala Murukku</Link>
															</li>
															<li>
																<Link to="/spicy-kerala-murukku">Spicy Kerala Murukku</Link>
															</li>
															<li>
																<Link to="/butter-murukku">Butter Murukku</Link>
															</li>
															<li>
																<Link to="/ribbon-pakkoda">Ribbon Pakkoda</Link>
															</li>
															<li>
																<Link to="/tapioca-chips">Tapioca Chips</Link>
															</li>
														</ul>
													</div>

													<div className="col-md-3">
														<ul>

															<li>
																<Link to="/raagi-ribbon-pakoda">Raagi Ribbon Pakoda</Link>
															</li>
															<li>
																<Link to="/kara-sev">Kara Sev</Link>
															</li>
															<li>
																<Link to="/pepper-sev">Pepper Sev</Link>
															</li>
															<li>
																<Link to="/rice-murukku">Rice Murukku</Link>
															</li>
															<li>
																<Link to="/omapodi">Omapodi</Link>
															</li>
															<li>
																<Link to="/special-mixture">Special mixture</Link>
															</li>
															<li>
																<Link to="/mixture">Mixture</Link>
															</li>
															<li>
																<Link to="/tapioca-stick">Tapioca Stick</Link>
															</li>
														</ul>
													</div>

													<div className="col-md-3">
														<ul>
															<li>
																<Link to="/aval-mixture">Aval Mixture</Link>
															</li>
															<li>
																<Link to="/corn-flakes-mixture">Corn Flakes Mixture</Link>
															</li>
															<li>
																<Link to="/totmato-mixture">Totmato Mixture</Link>
															</li>
															<li>
																<Link to="/raagi-mixture">Raagi Mixture</Link>
															</li>
															<li>
																<Link to="/sweet-mixture">Sweet Mixture</Link>
															</li>
															<li>
																<Link to="/mint-mixture">Mint Mixture</Link>
															</li>
															<li>
																<Link to="/masala-peanuts">Masala Peanuts</Link>
															</li>
															<li>
																<Link to="/triangle-chips">Triangle Chips</Link>
															</li>

														</ul>
													</div>
												</div>
											</div>
										</li>
									</ul>

								</li>


								<li>
									<Link to="/enquiry" className="menu-item">Enquiry</Link>
								</li>
								<li>
									<Link to="/contact-us" className="menu-item">Contact Us</Link>
								</li>


							</ul>
						</div>
					</div>
				</nav>

			</header>

		);
	}

}
export default Header;