import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import API from '../../../utils/API';
import {Api} from '../../../utils/Variables';
import {getCookieField} from '../../../utils';

import FACTORY_BUILDING from '../../../assets/images/banners/factory-building.jpg';

class About extends Component{
	constructor(props){
		super(props);
		this.state = {
			
		}
	}
	
	componentDidMount(){
	
	}
	
	
	render(){
		
		
		return(
			<section id="banner22" className="banner banner-2 text-center bg-gray pt-50 pb-50">
				<div className="container">
					<div className="row">
						<div className="col-xs-12 col-sm-6 col-md-6">
							<div className="heading heading-1 text--center">
								<p className="heading--subtitle">Welcome</p>
								<h2 className="heading--title">Chelliah chettiar firm Exporter of Savouries</h2>
								<div className="divider--shape-4"></div>
								<p className="heading--desc text--left">
									Commodities trading cum manufacturing company founded on a firm footing embedded with in-depth knowledge of Food
								</p>
								<p className="heading--desc">
									<b>Produce, its related practices & its trade across the world.</b> 
									<br />
									With head quarters in Kovilpatti, India - the company is strategically located to meet the global demands of commodities which form the current focus of the company. We have built our connections across markets to help you source the "right produce at the right quality and price".
									</p>
							   
							</div>
							
						</div>
						
						<div className="col-xs-12 col-sm-6 col-md-6">
							<div className="banner-img mt-20">
								<img src={FACTORY_BUILDING} style={{width: '100%'}} alt="image" />
							</div>
						</div>
						
					</div>
					
				</div>
				
			</section>
		);
	}
	
}
export default About;
