import React, { Component, Suspense, lazy } from 'react';
import { BrowserRouter , Switch, Route, Link, HashRouter, IndexRoute, Redirect } from 'react-router-dom';
import Loader from '../views/Loader';
import SiteLayout from '../layouts/SiteLayout';


export default () => (
	
<BrowserRouter basename="/" >
	<Switch>
		<Route path="" exact component={SiteLayout} />
	</Switch>
</BrowserRouter >
);