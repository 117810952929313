import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import API from '../../../utils/API';
import {Api} from '../../../utils/Variables';
import {getCookieField} from '../../../utils';

import BANNER_1 from '../../../assets/images/banners/7.jpg';

class SpecialTwo extends Component{
	constructor(props){
		super(props);
		this.state = {
			
		}
	}
	
	componentDidMount(){
	
	}
	
	
	render(){
		
		
		return(
		<section id="banner22" className="banner banner-2 text-center bg-gray pt-50 pb-50">
			<div className="container">
				<div className="row">
					<div className="col-xs-12 col-sm-6 col-md-6">
						<div className="heading heading-1 text--center">
							
							<h2 className="heading--title">Pepper sev</h2>
							<div className="divider--shape-4"></div>
							<p className="heading--desc text--left">
								&emsp; We make pepper sev with Natural organic black pepper which is enhancing protect against cell damages, improve nutrients absorption and activate antioxidants of human body, which has been manufacturing with consistent of health consciousness and amazing manufacturing practice .
							</p>							
						</div>
					  
					</div>
					
					<div className="col-xs-12 col-sm-6 col-md-6">
						<div className="banner-img mt-20">
							<img src={BANNER_1} style={{width: '100%'}} alt="image" />
						</div>
					</div>
					
				</div>
			</div>
		</section>
		);
	}
	
}
export default SpecialTwo;
